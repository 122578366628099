import React from 'react';
import Layout from '../../components/App';
import ConnectedLogin from './../../routes/login/Login';
import { useRouter } from 'next/router';
import process from 'process';
import { GetStaticPropsResult } from 'next';

export default function LoginPage({ newsFeed }: { newsFeed: any }) {
  const router = useRouter();
  const url = tryBase64Decode(router.query.redirect_url as string);

  return (
    <Layout title={'Login'} hideNav={true} fullWidth={true} hideTitle={true}>
      <ConnectedLogin redirectUrl={url} newsFeed={newsFeed} />
    </Layout>
  );
}

const tryBase64Decode = (value: string | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }
  try {
    return atob(value);
  } catch (ex) {
    return value;
  }
};

export async function getStaticProps(): Promise<GetStaticPropsResult<{ newsFeed: any }>> {
  try {
    const newsfeed = 79722; // "What's new" newsfeed ID
    const url = `https://api.intercom.io/news/newsfeeds/${newsfeed}/items`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.INTERCOM_API_KEY}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await response.json();
    return { props: { newsFeed: data }, revalidate: 60 * 5 };
  } catch (error) {
    console.error('Error fetching newsfeed', error);
    return {
      props: { newsFeed: null },
    };
  }
}
